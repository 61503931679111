import React from "react"

import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Button from "react-bootstrap/Button"

const ContactCTA = () => {
  return (
    <Container fluid>
      <Row className="bg-pattern p-5">
        <Col className="d-flex flex-column text-center">
          <h3 className="py-2 mb-0">Always happy to hear from you</h3>
          <p>to answer any questions or book an appointment</p>
          <Button href="/contact" size="lg" className="py-2 px-4 mx-auto mt-2 bg-white" variant="outline-dark">
            Get in touch
          </Button>
        </Col>
      </Row>
    </Container>
  )
}

export default ContactCTA
