import React from "react"
import { GatsbySeo } from 'gatsby-plugin-next-seo';

import Layout from "../components/layout"
import ContactCTA from "../components/cta-contact"

import Jumbotron from "react-bootstrap/Jumbotron"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Image from "react-bootstrap/Image"
import Button from "react-bootstrap/Button"

import crossroads from "../images/crossroads.svg"
import thoughts from "../images/thoughts.svg"
import friends from "../images/friends.svg"
import bed from "../images/bed.svg"
import walk from "../images/walk.svg"

const ShouldI = props => {
  return(
    <Layout>
      <GatsbySeo
        title="Should I Get Counselling?"
        description="10 things to look out for if you're not sure"
        canonical="https://yourtherapydoncaster.co.uk/should-I-get-counselling"
        openGraph={{
         url: "https://yourtherapydoncaster.co.uk/should-I-get-counselling",
         title: "Should I Get Counselling?",
         description: "10 things to look out for if you're not sure",
         site_name: "YourTherapy",
       }}
      />
      <Jumbotron fluid className="pt-3 pb-0 bg-pattern">
        <Container>
          <Row xs={1} lg={2} className="align-items-end">
            <Col className="px-4 px-md-0 pb-2 pb-lg-5">
              <h1>Should I get counselling?</h1>
              <p className="my-4">
                One of the most common misconceptions about talking therapy is that you need to be in a really bad place before
                you take steps or reach out for help. If you're already there, then it's not too late to get help. But if you're
                experiencing any of the things in the list below, counselling might be able to help.
              </p>
              <p>There are times in all of our lives when we might feel like we need some help and support.
              When reading the indicators below, please bear in mind that these are only general indicators, not a test, and you may not connect with any of these
              and still benefit from counselling. </p>
              <p>I'm always happy to hear from you, so if you've got any questions about counselling or you'd like to arrange an appointment please get in touch.</p>
              <Button href="/contact/" className="d-block d-md-inline p-2 mx-auto m-md-0 mb-md-5 bg-white" variant="outline-dark">
                Contact
              </Button>
            </Col>
            <Col>
              <Image src={crossroads} fluid alt=""/>
            </Col>
          </Row>
        </Container>
      </Jumbotron>
      <Container fluid="xl">
        <Row className="my-5">
          <Col xs={12} sm={{span:10, offset:1}} md={{span:5, offset:1}} xl={{span:5,offset:0}} className="p-3 pl-sm-0 pr-lg-5 mt-lg-3">
            <p class="text-uppercase font-weight-bolder">You're feeling overwhelmed</p>
            <p>A lot of us have been taught to keep our emotions hidden. Over time this can take a toll.
            If you find yourself having regular angry outbursts or you feel like you can't stop crying, counselling can help.</p>
            <p class="text-uppercase font-weight-bolder">You find it difficult to concentrate</p>
            <p>This can affect all aspects of your life, whether it's work, school or even finishing a film. You might be finding making
            decisions especially difficult, even ones that you made previously without pause for thought.
            If your mind is constantly wandering it can be an indicator that something is going on that should be explored.</p>
          </Col>
          <Col xs={12} sm={{span:10, offset:1}} md={{span:6, offset:0, order:'first'}} xl={{span:7}} className="px-5 px-sm-4 d-flex flex-column ml-xl-n5 mr-xl-5" >
            <Image src={thoughts} fluid alt=""/>
          </Col>
        </Row>
      </Container>
      <Container fluid="xl">
        <Row className="my-3">
          <Col xs={12} sm={{span:10, offset:1}} md={{span:5, offset:1}} xl={{span:5,offset:0}} className="p-3 pl-sm-0 pr-lg-5">
            <p class="text-uppercase font-weight-bolder">Nothing excites you any more</p>
            <p>If the expression "stuck in a rut" resonates with you, you're struggling for motivation or the things you used to enjoy stop being fun,
             you may be wondering whether you’ll ever feel yourself again. It’s normal to feel low and down at times but if you notice this becomes a
             pattern or you’ve felt this way for a couple of weeks or more, this could be an indicator of depression.
            </p>
            <p class="text-uppercase font-weight-bolder">Your friends are concerned</p>
            <p>Your friends and family may notice changes in your mood and behaviour that you don't pick up on. Some may have had
            some sort of therapy themselves and know what to look out for. It can be tempting to ignore things that we don't want
            to hear but often it can be what we need.
            </p>
          </Col>
          <Col xs={12} sm={{span:10, offset:1}} md={{span:6, offset:0}} xl={{span:7}} className="px-5 px-sm-4 d-flex flex-column mr-xl-n5 ml-xl-5" >
            <Image src={friends} fluid alt=""/>
          </Col>
        </Row>
      </Container>
      <Container fluid="xl">
        <Row className="my-3">
          <Col xs={12} sm={{span:10, offset:1}} md={{span:5, offset:1}} xl={{span:5,offset:0}} className="p-3 pl-sm-0 pr-lg-5 mt-lg-3">
            <p class="text-uppercase font-weight-bolder">You've pulled away from your friends</p>
            <p>This may be related to the previous entry or a clue by itself. Isolation happens when you begin to dread seeing friends
            or you start avoiding social situations. This is a sign that you may be struggling with your mental health.
            </p>
            <p class="text-uppercase font-weight-bolder">You struggle sleeping</p>
            <p>Sleep, both quality and quantity, is a useful way to measure how you're feeling. You may have started struggling with insomnia,
            where you cannot sleep at night or you wake up very early and cannot fall asleep again. Or you may be waking up and feeling like you
            haven't had any rest. If you notice any unexplained changes in your sleep schedule or this has been a feature of your life for a while,
            it can be a strong indicator of emotional or mental struggles.
            </p>
          </Col>
          <Col xs={12} sm={{span:10, offset:1}} md={{span:6, offset:0, order:'first'}} xl={{span:7}} className="px-5 px-sm-4 d-flex flex-column ml-xl-n5 mr-xl-5" >
            <Image src={bed} fluid alt=""/>
          </Col>
        </Row>
      </Container>
      <Container fluid="xl">
        <Row className="my-3">
          <Col xs={12} sm={{span:10, offset:1}} md={{span:5, offset:1}} className="p-3 pl-sm-0 pr-lg-5">
            <p class="text-uppercase font-weight-bolder">You keep getting ill</p>
            <p>Our body and our mind are deeply connected. Stress causes our immune system to be less effective, especially over longer periods. If you find
            yourself with sudden unexplained aches or you pick up every bug that's going around then your mental health may be affecting your physical health.
            </p>
            <p class="text-uppercase font-weight-bolder">You're drinking more</p>
            <p>Alcohol has a numbing effect on the mind and the body. If you're drinking regularly and it's increasingly difficult to resist the urge then
             this could be a good time to reach out.
            </p>
          </Col>
          <Col xs={12} sm={{span:10, offset:1}} md={{span:5, offset:1}} className="p-3 pl-sm-0 pr-lg-5">
            <p class="text-uppercase font-weight-bolder">You feel like you keep making the same bad decisions</p>
            <p>Many of us get stuck in a pattern of decision making that does not benefit us or our wellbeing. If you notice
            yourself making the same mistakes then talking to a counsellor can help find a way to break the habit.
            </p>
            <p class="text-uppercase font-weight-bolder">Your relationship is a struggle</p>
            <p>This can mean you and your partner always seem to fall out, or you can't maintain one relationship for long.
            Therapy can be a safe place to explore how your past relationships affect your present.
            </p>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row xs={1}>
          <Col md={{span:8, offset:2}} className="d-flex flex-column align-content-center">
            <Image src={walk} fluid alt=""/>
          </Col>
        </Row>
      </Container>
      <ContactCTA />
    </Layout>
  )
}

export default ShouldI
